import React from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import Logo from '../../../images/home/AS-Logo.svg';

import styles from './home.module.scss';

export function Home() {
  const { portrait } = useStaticQuery(graphql`
    query {
      portrait: file(name: { eq: "Andreas_Portrait" }) {
        childImageSharp {
          fluid(
            quality: 100
            maxWidth: 2000
            srcSetBreakpoints: [340, 768, 2000]
            traceSVG: { turnPolicy: TURNPOLICY_MAJORITY, background: "#0e1f38", color: "#0ec2a6" }
          ) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <div className={styles.wrapper}>
      <img src={Logo} className={styles.logo} width="46px" height="48px" alt="Andreas Straub Logo" />
      <div className={styles.introWrapper}>
        <h1>
          Hi, mein Name ist Andreas Straub. Ich bin Cloud Architekt, Entwickler und Inhaber von{' '}
          <a href="https://evelan.de/" target="_blank" rel="noopener noreferrer">
            Evelan GmbH
          </a>
          .
        </h1>
        <h2>
          Haben Sie Fragen bzgl. eines Projektes oder einer Idee? Lassen Sie uns sprechen:{' '}
          <a href="mailto:kontakt@evelan.de" className={styles.email}>
            kontakt@evelan.de
          </a>{' '}
        </h2>
        <div className={styles.imageWrapper}>
          <Img
            className={styles.portrait}
            fluid={portrait.childImageSharp.fluid}
            loading="auto"
            alt="Andreas Straub Portrait"
          />
        </div>
      </div>
    </div>
  );
}
