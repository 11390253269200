import React from 'react';
import clsx from 'clsx';
import styles from './cookie-controls.module.scss';

export const CookieSwitch = ({ id = 'cookie-switch', checked, onChange = () => {}, disabled }) => (
  <div className={clsx(styles.switch, disabled && styles.disabled)}>
    <input id={id} type="checkbox" checked={checked} onChange={() => onChange()} disabled={disabled} />
    <label htmlFor={id}>{`${id} switch toggle`}</label>
  </div>
);
