import React from 'react';

import styles from './academic.module.scss';

export function Academic() {
  const academicList = [
    {
      academic: 'Dipl. Medieninformatik (FH)',
      name: 'FH Wedel',
      startDate: 'Oktober 1999',
      endDate: 'März 2003',
      skills: [
        'Programmierung',
        'Datenbanken',
        'Computergrafik',
        'Mediengestaltung',
        'Systementwicklung',
        'Medientechnik und Rechnernetze',
      ],
    },
    {
      academic: 'Abitur',
      name: 'Kaiser‐Friedrich‐Ufer Gymnasium / Hamburg',
      startDate: 'November 1993',
      endDate: 'Juli 1998',
    },
  ];

  return (
    <>
      <h1 className={styles.title}>academische laufbahn</h1>
      <div className={styles.wrapper}>
        {academicList.map(({ academic, name, startDate, endDate, skills }, i) => (
          <div key={i} className={styles.item}>
            <h2>{academic}</h2>
            <h3>{name}</h3>
            <p>{`${startDate} - ${endDate}`}</p>
            {!!skills && (
              <ul className={styles.skillsWrapper}>
                {skills.map((item) => (
                  <li key={item}>{item}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </>
  );
}
