import React from 'react';
import styles from './cookie-controls.module.scss';

export const CookieButton = ({ children, onClick, variant = 'default', className }) => {
  return (
    <button aria-label="cookie button" onClick={onClick} variant={variant} className={styles.cookieButton}>
      {children}
    </button>
  );
};
