import React from 'react';
import clsx from 'clsx';
import styles from './button.module.scss';

export function Button({ className, variant = 'primary', children, type = 'button', ...other }) {
  return (
    <button
      className={clsx(
        styles.button,
        variant === 'text' && styles.text,
        variant === 'primary' && styles.primary,
        variant === 'secondary' && styles.secondary,
        variant === 'yellow' && styles.yellow,
        variant === 'dark' && styles.dark,
        className
      )}
      aria-label="btn"
      type={type}
      {...other}
    >
      {children}
    </button>
  );
}
