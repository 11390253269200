import React, { useState, useEffect } from 'react';
import clsx from 'clsx';

import { useMedia } from '../../../hooks/useMedia';

import styles from './burger.module.scss';

export function BurgerMenu({ isOpen, onClick }) {
  const [isActive, setActiveMenu] = useState(false);
  const isDesktop = useMedia(['(min-width: 1024px'], [true], [false]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const isRoot = window.location.pathname === '/';

      if (!isOpen) {
        if (isDesktop && !isRoot) {
          setActiveMenu(true);
        } else {
          setActiveMenu(false);
        }
      } else {
        setActiveMenu(false);
      }
    }
  }, [isDesktop, isOpen]);

  return (
    <div className={styles.burgerContainer}>
      <button
        name="menu-button"
        aria-label="Toggle menu"
        className={clsx(styles.burger, isActive && styles.active, isOpen && styles.openMenu)}
        onClick={onClick}
        type="button"
      >
        {isOpen ? 'Schließen' : 'Menu'}
      </button>
    </div>
  );
}

export default BurgerMenu;
