import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { Container } from '../container/container';
import { Button } from '../button/button';
import Chevron from '../../images/icons/chevron-icon.svg';
import { useMedia } from '../../hooks/useMedia';
import { sectionData } from '../sections/sections';

import styles from './footer.module.scss';

export function Footer({ scrollToClass, extraLinks, setActiveMenu, fixedFooter }) {
  const { sections } = sectionData();
  const isDesktop = useMedia(['(min-width: 1024px)'], [true], false);
  const [wrapper, setWrapper] = useState();

  const scrollTop = () => {
    if (wrapper) {
      wrapper.scrollTo(
        {
          top: 0,
          left: 100,
          behavior: 'smooth',
        },
        { passive: true }
      );
    }

    if (setActiveMenu) {
      setActiveMenu(`#${sections[0].id}`);
    }
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      setWrapper(isDesktop && setActiveMenu ? document.querySelector(`.${scrollToClass}`) : window);
    }
  }, [isDesktop, scrollToClass, setActiveMenu]);

  return (
    <Container className={clsx(styles.container, fixedFooter && styles.fixed)}>
      <div className={styles.extraLinksWrapper}>
        {extraLinks.map(({ name, type, link }) => {
          return (
            type === 'external' && (
              <a key={name} href={link} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            )
          );
        })}
      </div>
      <p>© {new Date().getFullYear()} ANDREAS-STRAUB.BIZ- ALL RIGHTS RESERVED</p>
      <div className={styles.btnScrollTopWrapper}>
        <div className={styles.extraLinksWrapper}>
          {extraLinks.map(({ name, type, link }) => {
            return (
              type === 'internal' && (
                <Link key={name} to={link}>
                  {name}
                </Link>
              )
            );
          })}
        </div>
        <Button onClick={scrollTop} className={styles.btnScrollTop}>
          <img src={Chevron} aria-hidden="true" role="presentation" alt="Chevron" />
        </Button>
      </div>
    </Container>
  );
}
