import React, { useState } from 'react';
import { animated as a, useSpring } from 'react-spring';
import useMeasure from 'react-use-measure';
import { ResizeObserver } from '@juggle/resize-observer';
import { CookieSwitch } from '../cookies-controls/cookie-switch';
import clsx from 'clsx';
import styles from './cookie-category.module.scss';

export const CookieCategory = ({ title, previewContent, moreContent, onAccept, isAccepted, switchDisabled }) => {
  const [showMore, setShowMore] = useState(false);
  const [previewRef, { height: previewHeight }] = useMeasure({ polyfill: ResizeObserver });
  const [moreRef, { height: moreHeight }] = useMeasure({ polyfill: ResizeObserver });

  const heightSpring = useSpring({
    height: showMore ? moreHeight + previewHeight : previewHeight,
  });

  return (
    <div className={styles.cookieCategory}>
      <p className={styles.category}>
        <strong>{title}</strong>
      </p>
      <button className={clsx(styles.more, showMore && styles.active)} onClick={() => setShowMore(!showMore)}>
        mehr
      </button>
      <a.div className={styles.hider} style={heightSpring}>
        {previewContent && (
          <div className={styles.content} ref={previewRef}>
            {previewContent}
          </div>
        )}
        <div className={styles.content} ref={moreRef}>
          {moreContent}
        </div>
      </a.div>
      <div className={styles.switchContainer}>
        <CookieSwitch
          id={title}
          onChange={() => onAccept(!isAccepted)}
          checked={isAccepted}
          disabled={switchDisabled}
        />
      </div>
    </div>
  );
};
