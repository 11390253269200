import React from 'react';

import CloudArchitectureIcon from '../../../images/services/cloud-architecture.svg';
import SoftwareDesignIcon from '../../../images/services/software-design.svg';
import CloudSoftwareDevelopmentIcon from '../../../images/services/cloud-software-development.svg';
import JamstackWebappDevelopmentIcon from '../../../images/services/jamstack-webapp-development.svg';

import styles from './services.module.scss';

export function Services() {
  const servicesList = [
    {
      name: 'Cloud Architektur',
      icon: CloudArchitectureIcon,
    },
    {
      name: 'Software Design',
      icon: SoftwareDesignIcon,
    },
    {
      name: 'Cloud Software Entwicklung',
      icon: CloudSoftwareDevelopmentIcon,
    },
    {
      name: 'JAMstack Webapp Entwicklung',
      icon: JamstackWebappDevelopmentIcon,
    },
  ];

  return (
    <>
      <h1 className={styles.title}>leistungen</h1>
      <div className={styles.wrapper}>
        {servicesList.map(({ name, icon }) => (
          <div key={name} className={styles.item}>
            <img src={icon} alt={name} />
            <p>{name}</p>
          </div>
        ))}
      </div>
    </>
  );
}
