import React from 'react';

import Check from '../../../images/icons/check-icon.svg';

import styles from './competencies.module.scss';

export function Competencies() {
  const competencyList = [
    'Amazon Web Services (AWS)',
    'DevOps',
    'JSON',
    'JavaScript',
    'JAMstack Webapps',
    'CI/CD',
    'Softwareentwicklung',
    'Cloud-Computing',
    'Entwicklung von Cloud-Anwendungen',
    'Cloud-Architektur',
    'Cloud-Sicherheit',
    'Software-Architektur',
    'Cloud Architektur',
    'Cloud-Anwendungen',
    'Cloud-Computing IaaS',
  ];

  return (
    <>
      <h1 className={styles.title}>kompetenzen</h1>
      <ul className={styles.wrapper}>
        {competencyList.map((item) => (
          <li key={item} className={styles.item}>
            <img src={Check} alt="Checkmark Icon" />
            <p>{item}</p>
          </li>
        ))}
      </ul>
    </>
  );
}
