import React from 'react';

import { createPhoneLink } from '../../../lib/helpers';

import styles from './kontakt.module.scss';

export function Kontakt() {
  const contactDetails = [
    {
      type: 'phone',
      name: 'Telefon',
      text: '+49 40 882159 40',
      isLink: true,
    },
    {
      type: 'email',
      name: 'E-Mail',
      text: 'kontakt@evelan.de',
      isLink: true,
    },
    {
      type: 'text',
      name: 'City',
      text: 'Hamburg, Germany',
      isLink: false,
    },
  ];

  return (
    <div className={styles.container}>
      <div>
        <h2>lassen sie uns in</h2>
        <h2 className={styles.headingStroke}>verbindung bleiben</h2>
        <div className={styles.detailsWrapper}>
          {contactDetails.map(({ type, name, text, isLink }, i) => (
            <div key={i} className={styles.item}>
              <p>{name}</p>
              {!!isLink ? (
                <a href={type === 'phone' ? createPhoneLink(text) : `mailto:${text}`}>{text}</a>
              ) : (
                <h3>{text}</h3>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
