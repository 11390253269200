import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';

import { Container } from '../container/container';
import { BurgerMenu } from './mobile-menu/burger-menu';
import { MobileMenu } from './mobile-menu/mobile-menu';
import { useMedia } from '../../hooks/useMedia';

import styles from './header.module.scss';

export function Header({ extraLinks, activeMenu, setActiveMenu }) {
  const [open, setOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const scrollCache = useRef({ pos: 0, debounce: false });
  const [showName, setShowName] = useState(true);
  const isDesktop = useMedia(['(min-width: 1024px)'], [true], false);

  useEffect(() => {
    if (open) {
      setShowName(true);

      if (!isDesktop) {
        document.body.style.overflow = 'hidden';
      }
    } else {
      if (!isDesktop) {
        document.body.style.overflow = 'auto';
      }
    }
  }, [isDesktop, open]);

  useEffect(() => {
    const handleScroll = (e) => {
      const { debounce } = scrollCache.current;
      if (debounce) {
        window.cancelAnimationFrame(debounce);
      }
      scrollCache.current.debounce = window.requestAnimationFrame(function () {
        const lastPosition = scrollCache.current.pos;
        const y = window.pageYOffset;
        if (lastPosition < y) {
          setShowName(false);

          if (!scrolled && y >= 100) {
            setScrolled(true);
          }
        } else {
          if (y === 0) {
            setShowName(true);
          }
          if (scrolled) {
            setScrolled(false);
          }
        }
        scrollCache.current.pos = window.pageYOffset;
      });
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [setScrolled, open, scrolled]);

  return (
    <>
      <div className={clsx(styles.headerWrapper, scrolled && styles.hideHeader, open && styles.headerWrapperBg)}>
        <Container component="header" className={styles.headerContainer}>
          <div className={styles.headerContent}>
            <div className={styles.logoContainer}>
              {showName && (
                <Link to="/">
                  <span>Andreas</span>Straub
                </Link>
              )}
            </div>
            <div className={styles.iconWrapper}>
              <BurgerMenu isOpen={open} onClick={() => setOpen(!open)} />
            </div>
          </div>
          <MobileMenu
            open={open}
            setOpen={setOpen}
            extraLinks={extraLinks}
            activeMenu={activeMenu}
            setActiveMenu={setActiveMenu}
          />
        </Container>
      </div>
    </>
  );
}
