import React, { useState } from 'react';
import { CookiesShowContext } from '../components/cookies/cookies-context/cookies-context';

/**
 * Base site context:
 * Contains the query for studios.
 * Contains the state for some UI modals/popups.
 */
export const AppContext = ({ children }) => {
  const [showCookies, setShowCookies] = useState(false);

  return <CookiesShowContext.Provider value={{ showCookies, setShowCookies }}>{children}</CookiesShowContext.Provider>;
};
