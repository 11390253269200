import { Home } from './home-section/home';
import { Services } from './services-section/services';
import { Competencies } from './competencies-section/competencies';
import { Career } from './career-section/career';
import { Academic } from './academic-section/academic';
import { Kontakt } from './kontakt-section/kontakt';

export function sectionData() {
  const sections = [
    {
      id: 'home',
      variant: 'secondary',
      section: Home,
    },
    {
      id: 'leistungen',
      variant: 'dark-secondary',
      section: Services,
    },
    {
      id: 'kompetenzen',
      variant: 'primary',
      section: Competencies,
    },
    {
      id: 'berufslaufbahn',
      variant: 'dark-secondary',
      section: Career,
    },
    {
      id: 'academische-laufbahn',
      variant: 'secondary',
      section: Academic,
    },
    {
      id: 'kontakt',
      variant: 'dark',
      section: Kontakt,
    },
  ];

  return {
    sections,
  };
}
