import { format } from 'date-fns-tz';
import { de } from 'date-fns/locale';

const timeZone = 'Europe/Berlin';

const normalBlockStyles = ['normal', 'subtitle', 'blockquote'];
export function isNormalBlock(block) {
  return block && normalBlockStyles.includes(block.style);
}

export function getUrlFromNavItem({ id, type, slug }, startPageId) {
  switch (type) {
    case 'staticPage':
    case 'page':
      return id === startPageId ? '/' : `/${slug}/`;
    case 'post':
      return `/blog/${slug}/`;
    default:
      return '/';
  }
}

export function isFluidAsset(asset) {
  return asset && asset.localFile && asset.localFile.childImageSharp && asset.localFile.childImageSharp.fluid;
}

export function isFixedAsset(asset) {
  return asset && asset.localFile && asset.localFile.childImageSharp && asset.localFile.childImageSharp.fixed;
}

export function isBlank(str) {
  return !str || /^\s*$/.test(str);
}

export function isNotBlankString(value) {
  return typeof value === 'string' && !/^\s*$/.test(value);
}

export function createPhoneLink(phone) {
  return phone ? `tel:${phone.split(' ').join('')}` : '';
}

export function getPlainTextFromBlockContent(allBlocks = []) {
  const blocks = allBlocks.find((block) => block._type === 'block');

  if (blocks && blocks.children) {
    return blocks.children
      .filter((child) => child._type === 'span')
      .map((span) => span.text)
      .join('');
  }

  return '';
}

export function getFirstNormalBlock(allBlocks = []) {
  if (!allBlocks || allBlocks.length < 1) {
    return [];
  }
  const blocks = allBlocks.filter((block) => block._type === 'block');
  const firstNormalBlock = blocks.find(isNormalBlock);
  return firstNormalBlock ? [firstNormalBlock] : [];
}

export function chunk(array, size) {
  if (!array || !array.length) {
    return [];
  }

  if (!size) {
    return array;
  }

  const firstChunk = array.slice(0, size); // create the first chunk of the given array
  if (!firstChunk || !firstChunk.length) {
    return array; // this is the base case to terminal the recursive
  }
  return [firstChunk].concat(chunk(array.slice(size, array.length), size));
}

export function deepFreeze(object) {
  // Retrieve the property names defined on object
  const propNames = Object.getOwnPropertyNames(object);

  // Freeze properties before freezing self

  for (let name of propNames) {
    let value = object[name];

    object[name] = value && typeof value === 'object' ? deepFreeze(value) : value;
  }

  return Object.freeze(object);
}

/**
 * This function extracts all the closest items in the array closest to the index.
 * It will fill one-side of the array with items if the index is too close to 1 edge.
 * @param posts Array to be sliced
 * @param currentIndex The center of the array to base the slice off
 * @param otherCount The number of items to be returned around the index
 */
export function getClosestPosts(posts, currentIndex, otherCount) {
  if (currentIndex >= posts.length) {
    console.error('Current index is higher than the posts.length. Out of bounds.');
    return posts;
  }

  if (currentIndex < 0) {
    console.error('Current index is less than 0, it is out of bounds');
    return posts;
  }

  const d = otherCount / 2;

  // We prefer more next posts than previous
  let neededLeftPosts = Math.floor(d);
  let neededRightPosts = Math.round(d);

  const leftPosts = posts.slice(0, currentIndex);
  const rightPosts = posts.slice(currentIndex + 1, posts.length);

  // If we need more posts to the left we us
  const adjustedLeftPosts =
    rightPosts.length < neededRightPosts
      ? Math.round(otherCount / 2) + (neededLeftPosts - rightPosts.length)
      : neededLeftPosts;

  const adjustedRightPosts =
    leftPosts.length < neededLeftPosts ? neededRightPosts + (neededLeftPosts - leftPosts.length) : neededRightPosts;

  return [...leftPosts.slice(adjustedLeftPosts * -1), ...rightPosts.slice(0, adjustedRightPosts)];
}

export function isDate(date) {
  return !isNaN(Date.parse(date));
}

/**
 * Converts an UTC Date string to the formatted date in  'Europe/Berlin' time zone
 * @param dateUtcStr
 * @param pattern date fns pattern.
 * @returns {string}
 */
export function formatDate(dateUtcStr, pattern = 'd. MMMM yyyy') {
  if (!isDate(dateUtcStr)) {
    return '';
  }
  const dateUtc = new Date(dateUtcStr);
  return format(dateUtc, pattern, { timeZone, locale: de });
}

export function formatDateTime(dateUtcStr) {
  return formatDate(dateUtcStr, 'd. MMMM yyyy - HH:mm');
}
