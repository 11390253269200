import React, { useEffect } from 'react';

import { AppContext } from '../../context/AppContext';
import { Header } from '../header/header';
import { Container } from '../container/container';
import { Footer } from '../footer/footer';
import { useMedia } from '../../hooks/useMedia';
import { CookiesSnackbar } from '../cookies/cookies';

import '../../styles/main.scss';

const extraLinks = [
  {
    name: 'Xing',
    type: 'external',
    link: 'https://www.xing.com/profile/Andreas_Straub42',
  },
  {
    name: 'LinkedIn',
    type: 'external',
    link: 'https://www.linkedin.com/in/andreas-straub/',
  },
  {
    name: 'Impressum',
    type: 'internal',
    link: '/impressum',
  },
  {
    name: 'Datenschutz',
    type: 'internal',
    link: '/datenschutz',
  },
];

export function LayoutContent({
  className,
  children,
  activeMenu,
  setActiveMenu,
  onScroll,
  hiddenOverflow,
  fixedFooter,
}) {
  const isDesktop = useMedia(['(min-width: 1024px)'], [true], false);

  useEffect(() => {
    document.body.style.overflow = hiddenOverflow && isDesktop ? 'hidden' : 'auto';
  }, [hiddenOverflow, isDesktop]);

  return (
    <AppContext>
      <Header extraLinks={extraLinks} activeMenu={activeMenu} setActiveMenu={setActiveMenu} />
      <Container className={className} onScroll={onScroll}>
        <main>{children}</main>
      </Container>
      <Footer
        scrollToClass={className}
        extraLinks={extraLinks}
        setActiveMenu={setActiveMenu}
        fixedFooter={fixedFooter}
      />
      <CookiesSnackbar />
    </AppContext>
  );
}
