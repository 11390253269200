import React, { useContext } from 'react';
import { CookieSwitch } from '../cookies-controls/cookie-switch';
import { CookiesContext } from '../cookies-context/cookies-context';
import { Button } from '../../button/button';
import styles from './cookies-prompt.module.scss';

export const CookiesPrompt = ({ onAcceptAll, onConfigure, onRejectAll }) => {
  const { acceptedCookies } = useContext(CookiesContext);

  return (
    <div className={styles.promptContent}>
      <div className={styles.content}>
        <h4>Cookie-Einstellungen</h4>
        <p>
          Wir verwenden Cookies, um Ihnen ein optimales Webseiten-Erlebnis zu bieten. Einige sind für den Betrieb der
          Seite und für die Steuerung unserer kommerziellen Unternehmensziele notwendig, während andere uns helfen diese
          Webseite und Ihre Nutzererfahrung zu verbessern.
        </p>
        <div>
          <div className={styles.switchContainer}>
            <div>
              <CookieSwitch id="cp-Necessary" checked={acceptedCookies.necessary} disabled={true} />
              <p className={styles.category}>Notwendig</p>
            </div>
            <div>
              <CookieSwitch id="cp-Performance" checked={acceptedCookies.performance} />
              <p className={styles.category}>Performance</p>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.actions}>
        <Button variant="primary" className={styles.acceptButton} onClick={onAcceptAll}>
          Alle akzeptieren
        </Button>
        <Button className={styles.textButton} variant="text" onClick={onRejectAll}>
          Nur notwendige
        </Button>
        <Button className={styles.textButton} variant="text" onClick={() => onConfigure()}>
          Individuelle Einstellung
        </Button>
      </div>
    </div>
  );
};
