import Cookies from 'js-cookie';

export const CATEGORIES = {
  PERFORMANCE: 'PERFORMANCE',
};

export const COOKIE_KEYS = {
  [CATEGORIES.PERFORMANCE]: 'gdpr-analytics-enabled',
};

export default {
  isInitial: () => {
    let isAllNull = true;
    Object.values(CATEGORIES).forEach((category) => {
      if (Cookies.get(COOKIE_KEYS[category]) !== undefined && isAllNull === true) {
        isAllNull = false;
      }
    });
    return isAllNull;
  },
  get: (category) => {
    return Cookies.get(COOKIE_KEYS[category]) === 'true';
  },
  update: (category, newValue) => {
    switch (category) {
      case CATEGORIES.PERFORMANCE: {
        Cookies.set('gdpr-analytics-enabled', newValue, { expires: 365, samesite: 'none', secure: true });
        if (!window.trackHotjar) {
          console.error('trackHotjar is not defined in window');
        }
        if (window.trackHotjar && newValue === true) {
          window.trackHotjar();
        }
        break;
      }
      default:
        break;
    }
  },
};
