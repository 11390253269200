import React, { useEffect } from 'react';
import clsx from 'clsx';
import { Link } from 'gatsby';
import { animated as a, useSpring, useTrail, config } from 'react-spring';

import { sectionData } from '../../sections/sections';

import styles from './mobile-menu.module.scss';

const easingConfig = {
  ...config.default,
  easing: (t) => ((t *= 2) <= 1 ? 1 - Math.sqrt(1 - t * t) : Math.sqrt(1 - (t -= 2) * t) + 1) / 2,
};

export const MobileMenu = ({ open, setOpen, extraLinks, activeMenu, setActiveMenu }) => {
  const { sections } = sectionData();

  const menuItems = sections.map(({ id }) => {
    return { name: id.replace('-', ' '), link: `#${id}` };
  });

  useEffect(() => {
    if (open) {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vhForMobile', `${vh}px`);
    }
  }, [open]);

  const cover = useSpring({
    height: `${open ? 100 : 0}%`,
    config: easingConfig,
    delay: open ? 100 : 220,
  });

  const menuTrail = useTrail(menuItems.length, {
    opacity: open ? 1 : 0,
    transform: open ? 'scale(1)' : 'scale(0.7)',
    config: {
      ...easingConfig,
      clamp: true,
      tension: 550,
    },
    delay: open ? 200 : 0,
  });

  const handleClick = (link) => {
    setOpen(false);

    if (setActiveMenu) {
      setActiveMenu(link);
    }
  };

  return (
    <nav className={styles.nav} style={{ pointerEvents: open ? 'all' : 'none' }}>
      <a.div className={styles.menuCover} style={cover}></a.div>
      <ul className={styles.menuItems}>
        {menuItems.map((menuItem, index) => {
          const { name, link } = menuItem;
          const trail = open ? menuTrail[index] : menuTrail[menuItems.length - index - 1];

          return (
            <a.li key={link} className={styles.menuItem} style={trail} onClick={() => handleClick(link)}>
              <Link to={`/${link}`} className={clsx(link === activeMenu && styles.activeNav)}>
                {name}
              </Link>
            </a.li>
          );
        })}
      </ul>
      <div className={styles.extraLinksWrapper}>
        <div className={styles.external}>
          {extraLinks.map(({ name, type, link }, index) => {
            const trail = open ? menuTrail[index] : menuTrail[menuItems.length - index - 1];

            return (
              type === 'external' && (
                <a.a
                  key={name}
                  href={link}
                  target="_blank"
                  className={styles.menuItem}
                  style={trail}
                  rel="noopener noreferrer"
                >
                  {name}
                </a.a>
              )
            );
          })}
        </div>
        <div className={styles.internal}>
          {extraLinks.map(({ name, type, link }, index) => {
            const trail = open ? menuTrail[index] : menuTrail[menuItems.length - index - 1];

            return (
              type === 'internal' && (
                <a.div key={name} style={trail}>
                  <Link to={link} className={styles.menuItem}>
                    {name}
                  </Link>
                </a.div>
              )
            );
          })}
        </div>
      </div>
    </nav>
  );
};
