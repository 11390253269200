import React from 'react';

import styles from './career.module.scss';

export function Career() {
  const careerList = [
    {
      position: 'Founder / Cloud Architekt / Entwickler',
      company: 'Evelan GmbH',
      startDate: 'Januar 2018',
      endDate: 'JETZT',
    },
    {
      position: 'Software Architekt',
      company: 'MGM Technology Partners GmbH',
      startDate: 'März 2010',
      endDate: 'Dezember 2017',
    },
    {
      position: 'Software Entwickler',
      company: 'Multi-M GmbH',
      startDate: 'April 2003',
      endDate: 'Februar 2010',
    },
  ];

  return (
    <>
      <h1 className={styles.title}>berufslaufbahn</h1>
      <div className={styles.wrapper}>
        {careerList.map(({ position, company, startDate, endDate }, i) => (
          <div key={i} className={styles.item}>
            <h2>{i < 10 ? `0${i + 1}` : i}</h2>
            <div className={styles.career}>
              <h2>{position}</h2>
              <div className={styles.details}>
                <span>{company}</span>
                <p>{`${startDate} - ${endDate}`}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
